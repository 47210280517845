import 'jquery';
import { default as $ } from 'jquery';
import 'slick-carousel';

jQuery(document).ready(function () {
    jQuery('.m-online-products.-isCarousel').each(function () {

        let slickConf = {
            ...slickConf,
            arrows: true,
            prevArrow: '<button type="button" class="slick-prev"><i class="fa-solid fa-chevron-left m-online-icon"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fa-solid fa-chevron-right m-online-icon"></i></button>',
            dots: false,
            infinite: false,
            slidesToShow: 2.5,
            slidesToScroll: 1,
            controls: true,
            autoplay: false,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 1.5,

                    }
                },
                {
                    breakpoint: 767,

                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };
        
    $(this).slick(slickConf);

    });
});

