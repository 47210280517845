// This file contains all parachute imports, e.g:
// import './parachute/foo/foo.js';
// import './parachute/bar/bar.js';

import './accordion/accordion';
import './banners/banners';
import './tabs/tabs';
import './content-listings/content-listings';
import './gallery/gallery';
import './reviews/reviews';
import './partners/partners';
import './icon-tabs/icon-tabs';
import './icon-grid/icon-grid';
import './tiers/tiers';
import './nav/nav';
import './mmenu/mmenu';
import './search/search';
import './header/header';
import './scroll-magic/scroll-magic';
import './modals/modals';
import './endless-scroll/endless-scroll';
import './fez-booking/fez-booking';
import './media-text/media-text';
import './awards/awards';
import './content-listings-timeline/content-listings-timeline';
import './coloured-block/coloured-block';
import './cart-summary/cart-summary';
import './basket-update/basket-update';
import './basket-overview/basket-overview';
import './content-listings-popular/content-listings-popular';
import './collection/collection';
import './timeline/timeline';
import './online-collection/online-collection';
import './archive/archive';
import './animation/animation';
import './age-cookie/age-cookie';
import './serves/serves';

// Publicly accessible methods for this build, useful for callbacks in script tags etc.
// Try and keep most things inside this webpack bundle though
import { publicExports } from './public-exports';
window._Parachute = publicExports || {};
