import 'jquery';
import { default as $ } from 'jquery';
import 'slick-carousel';

jQuery(document).ready(function () {
    if (jQuery(window).width() < 768) {
        jQuery('#productsCarousel').addClass('-isCarousel');
    } else {
        jQuery('#productsCarousel').removeClass('-isCarousel');
    }
});

jQuery(document).ready(function () {
    jQuery('.m-archive-row.-isCarousel').each(function () {

        let slickConf = {
            ...slickConf,
            arrows: true,
            prevArrow: '<button type="button" class="slick-prev"><i class="fa-solid fa-chevron-left m-archive-icon"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fa-solid fa-chevron-right m-archive-icon"></i></button>',
            dots: false,
            infinite: true,
            slidesToShow: 2.5,
            slidesToScroll: 1,
            controls: true,
            autoplay: false,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 1.5,

                    }
                },
                {
                    breakpoint: 767,

                    settings: {
                        slidesToShow: 1.5,
                    }
                }
            ]
        };
        
    $(this).slick(slickConf);

    });
});

jQuery(document).ready(function () {
    jQuery('.m-archive-merch-carousel.-isCarousel').each(function () {

        let slickConf = {
            ...slickConf,
            arrows: true,
            prevArrow: '<button type="button" class="slick-prev -merch"><i class="fa-solid fa-chevron-left m-online-icon"></i></button>',
            nextArrow: '<button type="button" class="slick-next -merch"><i class="fa-solid fa-chevron-right m-online-icon"></i></button>',
            dots: false,
            infinite: false,
            slidesToShow: 3.5,
            slidesToScroll: 1,
            controls: true,
            autoplay: false,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 2.5,

                    }
                },
                {
                    breakpoint: 767,

                    settings: {
                        slidesToShow: 1.5,
                    }
                }
            ]
        };
        
    $(this).slick(slickConf);

    });
});
