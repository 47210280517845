// import 'jquery';


// jQuery(document).ready(function () {
//     const events = document.getElementsByClassName('m-timline-col');
    
//     const observer = new IntersectionObserver(entries => {
//         // Loop over the entries
//         entries.forEach(entry => {
//             // If the element is visible
//             if (entry.isIntersecting) {
//                 // Add the animation class
//                 entry.target.classList.add('fade-in');
//             }
//         });
//     });

//     observer.observe(document.querySelector('#event1'));
//     observer.observe(document.querySelector('#event2'));
//     observer.observe(document.querySelector('#event3'));
//     observer.observe(document.querySelector('#event4'));
//     observer.observe(document.querySelector('#event5'));
//     observer.observe(document.querySelector('#event6'));
//     observer.observe(document.querySelector('#event7'));
//     observer.observe(document.querySelector('#event8'));
//     observer.observe(document.querySelector('#event9'));
//     observer.observe(document.querySelector('#event10'));
//     observer.observe(document.querySelector('#event11'));
//     observer.observe(document.querySelector('#event12'));
//     observer.observe(document.querySelector('#event13'));


    
//     jQuery(events).each(function () {
//         observer.observe(document.querySelector(this.id));
//         console.log(this.id);
//     });
    
//     // jQuery(".m-timeline-col").each(function() {
//         //     console.log(this.id);
//         //     observer.observe(document.querySelector(this.id));
//         // });
        
        
        
//     });