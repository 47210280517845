import 'jquery';

jQuery('#summary').on('click', function(){
    jQuery('#summaryInfo').toggleClass('-show');
});

jQuery('#summaryDropdown').on('click', function(){
    jQuery('#summaryInfo').toggleClass('-show');
});


jQuery('#orderDetailsDropdown').on('click', function(){
    jQuery('#orderDetails').toggleClass('-show');
});

jQuery(function() {
    var loc = window.location.href; // returns the full URL
    if(/checkout/.test(loc)) {
      jQuery('#paymentDetails').addClass('-current');
      jQuery('#paymentDetails').removeClass('-greyed');
    }
  });

  jQuery(function() {
    var loc = window.location.href; // returns the full URL
    if(/order-received/.test(loc)) {
      jQuery('#confirm').addClass('-current');
      jQuery('#confirm').removeClass('-greyed');
      jQuery('#paymentDetails').removeClass('-current');
      jQuery('#paymentDetails').addClass('-greyed');
    }
  });