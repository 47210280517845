import 'jquery';

jQuery(document).ready(function () {
    if (jQuery(window).width() < 768) {
        var header = jQuery("#header");
        jQuery(window).scroll(function() {
            var scroll = jQuery(window).scrollTop();
    
            if (scroll >= 50) {
                header.addClass("darkHeader");
            } else {
                header.removeClass("darkHeader");
            }
        });    
        
    } else {
        
        var header = jQuery("#header");
        jQuery(window).scroll(function() {
            var scroll = jQuery(window).scrollTop();
    
            if (scroll >= 50) {
                header.addClass("darkHeader");
            } else {
                header.removeClass("darkHeader");
            }
        });    
    }
});

jQuery('#mobileDropdownButton').on('click', function(){
    jQuery('#mobileDropdown').addClass('-show');
    jQuery('#body').addClass('position-fixed');
});

jQuery('#navClose').on('click', function(){
    jQuery('#mobileDropdown').removeClass('-show');
    jQuery('#body').removeClass('position-fixed');

});