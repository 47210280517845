import { default as $ } from 'jquery';
import 'slick-carousel';

$( document ).ready(function() {
    $('.m-serves-alt-list.-isCarousel').slick({
        dots: false,
        arrows: false,
        infinite: false,
        margin: 20,
        slidesToShow: 4,
        draggable: true,
        pauseOnHover: false,
        slidesToScroll: 1,
        controls: true,
        autoplay: false,
        rows: 0,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 3.5,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3.2,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 565,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });
});